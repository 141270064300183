<template>
  <!--Pagar con SM-->
  <div class="container">
    <div class="row mx-2">
      <div class="col-12 mt-3">
        <b-card body class="text-center card-shadow">
          <b-img
            fluid
            src="../../../assets/images/celcomLogo/logo.png"
            alt="Logo Celcom"
            style="height: 30px"
          />
        </b-card>
      </div>
    </div>

    <div class="row mx-2">
      <!-- Nombre y avatar -->

      <div class="col-12">
        <b-card body class="text-center card-shadow float">
          <div class="mb-3">
            <h3 class="my-1 font-weight-bolder">
              Suscripción Móviles
            </h3>
          </div>

          <div class="mb-3" v-if="screen == 2">
            <div class="row">
              <div class="col"></div>
              <!-- teléfono -->
              <div class="col-4 text-center">
                <b-form-group label="Teléfono móvil" label-for="phoneNumber" label-align="left">
                  <b-form-input
                    id="phoneNumber"
                    placeholder="Ingrese su número de móvil"
                    v-model="phoneNumber"
                    type="number"
                    :state="statePhoneNumber"
                  />
                </b-form-group>
              </div>
              <div class="col"></div>
            </div>

            <b-button
              variant="primary"
              class="w-25 mb-4 mt-1"
              @click="sendNumber"
            >
              Envíame el código
            </b-button>
          </div>

          <div v-if="screen == 3">
            <div class="mt-2 mb-1">
              <h5 class="my-1 colorSub">
                Recibirá un código en su teléfono móvil, ingréselo a
                continuación
              </h5>
            </div>

            <div class="row">
              <div class="col"></div>
              <!-- teléfono -->
              <div class="col-4 text-center">
                <b-form-group label="Código" label-for="pinCode" label-align="left">
                  <b-form-input
                    id="pinCode"
                    v-model="pinCode"
                    type="number"
                    :state="statePinCode"
                  />
                </b-form-group>
              </div>
              <div class="col"></div>
            </div>

            <b-button variant="primary" class="w-25 mb-1 mt-1" @click="sendPin">
              Confirmar
            </b-button>
          </div>

          <div v-if="screen == 4">
            <div class="mt-2 mb-1">
              <h3 class="my-1 colorSub">
                Suscripción exitosa !
              </h3>
              <p v-if="callback != ''">En 5 segundos será redireccionado</p>
            </div>
          </div>

          <div v-if="screen == -1">
            <div class="mt-2 mb-1">
              <h3 class="my-1 colorSub">
                No se pudo realizar la suscripción
              </h3>
            </div>
          </div>

          <div v-if="screen == -5">
            <div class="mt-2 mb-1">
              <h3 class="my-1 colorSub">
                Enlace invalido
              </h3>
            </div>
          </div>

          <div v-if="screen == -10">
            <div class="mt-2 mb-1">
              <h3 class="my-1 colorSub">
                Suscripción cancelada
              </h3>
            </div>
          </div>

          <div v-if="screen == 10">
            <div class="mt-2 mb-1">
              <h3 class="my-1 colorSub">
                Esta seguro que desea realizar la <br/>suscripción por $ {{amountS}}?<br/><br/>
                <b-button variant="primary" class="w-25 mb-1 mt-1" @click="cancelSusc">
                  Cancelar
                </b-button><br/>
                <b-button variant="primary" class="w-25 mb-1 mt-1" @click="validIp">
                  Confirmar
                </b-button>
              </h3>
            </div>
          </div>

          <div v-if="screen == 6">
            <div class="mt-2 mb-1">
              <h3 class="my-1 colorSub">
                Esta seguro que desea realizar la <br/>suscripción con el numero Entel: {{numberPhone}}?<br/><br/>
                <b-button variant="primary" class="w-25 mb-1 mt-1" @click="cancelSusc">
                  Cancelar
                </b-button><br/>
                <b-button variant="primary" class="w-25 mb-1 mt-1" @click="confirmarEntelNumber">
                  Confirmar
                </b-button>
              </h3>
            </div>
          </div>
          
          <div v-if="screen == 7">
            <div class="mt-2 mb-1">
              <h3 class="my-1 colorSub">
                Esta seguro que desea realizar la <br/>suscripción con el numero Movistar: {{numberPhone}}?<br/><br/>
                <b-button variant="primary" class="w-25 mb-1 mt-1" @click="cancelSusc">
                  Cancelar
                </b-button><br/>
                <b-button variant="primary" class="w-25 mb-1 mt-1" @click="confirmarMovistarNumber">
                  Confirmar
                </b-button>
              </h3>
            </div>
          </div>

          <div class="row" v-if="camposVacios">
            <div class="col"></div>
            <div class="col-6">
              <b-alert
                v-height-fade
                show
                dismissible
                fade
                class="mt-0"
                variant="warning"
              >
                <div class="alert-body">
                  <span>Debe completar los campos obligatorios</span>
                </div>
              </b-alert>
            </div>
            <div class="col"></div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink,
  BFormInput,
  BButton,
  BForm,
  BImg,
  BCard,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAlert,
  BFormCheckbox,
  BFormGroup,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import {
  backendUrl,
  frontEndUrl,
  urlEmpello
} from "@/config";
import axios from "axios";

export default {
  components: {
    BLink,
    BFormInput,
    BButton,
    BForm,
    BImg,
    BCard,
    BAvatar,
    VuexyLogo,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ToastificationContent,
    BAlert,
    vSelect,
    BFormCheckbox,
    BFormGroup,
  },
  created() {
    this.$store.commit("appConfig/changeLoading", true);
  },
  data() {
    return {
      pinCode: "",
      phoneNumber: "",
      statePhoneNumber: null,
      statePinCode: null,
      codigoEnviado: false,
      camposVacios: null,
      screen: 1,
      numberPhone: '',
      respuesta: {},

      toStore: '',

      message: "",
      code: "",
      company: "",

      accessKey: this.$route.params.accesskey,
      network: this.$route.params.network ? this.$route.params.network : '',

      amountS: 0,
      refval: null,

      eop: 1,
      estype: 0,

      callback: ''
    };
  },

  mounted() {
    let empelloScript = document.createElement('script');
    empelloScript.setAttribute('src', urlEmpello);
    document.head.appendChild(empelloScript);

    var axios = require('axios');

    /*if(!this.$route.query.id){
    this.$store.commit("appConfig/changeLoading", false);
      this.screen = -5;
      setTimeout(() => {
        if(window.location.search.indexOf('?') != -1){
          window.location.href = window.location.href + '&id=8888'
        }else{
          window.location.href = window.location.href + '?id=8888'
        }
      }, 10000);
    }else{*/
      try{
        if(window.location.search.length > 0){
          this.toStore = '{"'+window.location.search.substr(1).replaceAll('=','":"').replaceAll('&','","')+'"}'
        }
      }catch(e){}
      var t = this;
      if (this.$route.query.sId) {
        console.log("SID obtenido, confirmando entel");
        axios
          .post(backendUrl + "/mobile/confir-entel-sid", {
            sid: this.$route.query.sId,
            store: t.toStore,
            key: this.accessKey,
            network: this.network
          })
          .then(function (response) {
            if (response) {
              t.$store.commit("appConfig/changeLoading", false);
              switch (response.data.code) {
                case 4:
                  console.log("Suscripción entel exitosa");
                  t.screen = 4;
                  if(response.data.callback){
                    t.callback = response.data.callback;
                    setTimeout(() => {
                      window.location.href = response.data.callback;
                    }, 5000);
                  }
                  //t.message = "suscripción exitosa";
                  //t.code = JSON.stringify(response.data, null, 4);
                  break;
                case 2:
                  t.screen = 2;
                  break;
                case 1:
                  t.screen = 2;
                  break;
                case -5:
                  t.screen = -5;
                  break;
                case 6:
                  t.screen = 6;
                  t.respuesta = response.data.body;
                  t.numberPhone = response.data.number;
                  
                  //console.log('data-case: ',response.data);
                  break;
                case 7:
                  t.screen = 7;
                  t.respuesta = response.data;
                  t.numberPhone = response.data.number;
                  //console.log('data-case: ',response.data);
                  break;
              }
            } else {
              t.screen = -1;
            }
          })
          .catch(function (error) {
            console.log(error);
            t.screen = -1;
          });
      } else {
        var t = this;
        var data = JSON.stringify({
          key: this.accessKey,
          network: this.network
        });

        var config = {
          method: 'post',
          url: backendUrl +'/mobile/amount',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };

        axios(config)
        .then(function (response) {
          t.$store.commit("appConfig/changeLoading", false);
          if(response.data.amount){
            if(response.data.param){
              if(!t.$route.query[response.data.param]){
                t.$store.commit("appConfig/changeLoading", false);
                t.screen = -5;
                setTimeout(() => {
                  if(window.location.search.indexOf('?') != -1){
                    window.location.href = window.location.href + '&id=8888'
                  }else{
                    window.location.href = window.location.href + '?id=8888'
                  }
                }, 10000);
              }else{
                t.refval = JSON.stringify(t.$route.query)
                t.eop = response.data.entel_op;
                t.estype = response.data.entel_stype;
                console.log(t.refval)
                t.amountS = response.data.amount
                t.screen = 10;
              }
            }else{
              t.screen = -5;
            }
          }else{
            t.screen = -5;
          }
        })
        .catch(function (error) {
          t.$store.commit("appConfig/changeLoading", false);
          t.screen = -5;
        });

        //this.validIp();
      }
    //}
  },
  methods: {
    async cancelSusc(){
      this.screen = -10;
    },
    async confirmarEntelNumber(){
      var t = this;
      t.$store.commit("appConfig/changeLoading", true);
      var axios = require('axios');
      var tokenEmpello;
      await Empello.getToken((x)=>tokenEmpello=x);
      var data = JSON.stringify({
        number: t.numberPhone,
        datos: t.respuesta,
        network: this.network,
        tokenEmpello: tokenEmpello,
      });

      var config = {
        method: 'post',
        url: backendUrl + '/mobile/confirm-entel-number',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      axios(config).then((response)=>{
        switch (response.data.code) {
          case 4:
            t.screen = 4;
            if(response.data.callback){
              t.callback = response.data.callback;
              setTimeout(() => {
                window.location.href = response.data.callback;
              }, 5000);
            }
            //t.message = "suscripción exitosa";
            //t.code = JSON.stringify(response.data, null, 4);
            break;
          case 2:
            t.screen = 2;
            break;
          case 1:
            t.screen = 2;
            break;
          case -5:
            t.screen = -5;
            break;
          case -1:
            t.screen = -1;
            break;
        }
        t.$store.commit("appConfig/changeLoading", false);
      })
    },

    async confirmarMovistarNumber(){
      var t = this;
      t.$store.commit("appConfig/changeLoading", true);
      var axios = require('axios');
      var tokenEmpello;
      await Empello.getToken((x)=>tokenEmpello=x);
      var data = JSON.stringify(
        {
          ...t.respuesta,
          network: this.network,
          tokenEmpello: tokenEmpello,
        }
      );
      var config = {
        method: 'post',
        url: backendUrl + '/mobile/confirm-movistar-number',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      axios(config).then((response)=>{
        switch (response.data.code) {
          case 4:
            t.screen = 4;
            if(response.data.callback){
              t.callback = response.data.callback;
              setTimeout(() => {
                window.location.href = response.data.callback;
              }, 5000);
            }
            //t.message = "suscripción exitosa";
            //t.code = JSON.stringify(response.data, null, 4);
            break;
          case 2:
            t.screen = 2;
            break;
          case 1:
            t.screen = 2;
            break;
          case -5:
            t.screen = -5;
            break;
          case -1:
            t.screen = -1;
            break;
        }
        t.$store.commit("appConfig/changeLoading", false);
      });
    },

    async sendNumber() {
      var t = this;
      var axios = require('axios');
      var data = JSON.stringify({
        "phone": this.phoneNumber,
        "store": t.toStore,
        "key": t.accessKey,
        "network": this.network
      });

      var config = {
        method: 'post',
        url: backendUrl + '/mobile/enter-number',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      t.$store.commit("appConfig/changeLoading", true);
      axios(config)
      .then(function (response) {
        t.$store.commit("appConfig/changeLoading", false);
          switch (response.data.code) {
            case 2:
              t.screen = 3;
              t.company = response.data.company;
              break;
            case 1:
              t.screen = -1;
              break;
            case -5:
              t.screen = -5;
              break;
          }
      })
      .catch(function (error) {
        console.log(error);
      });

    },
    async sendPin() {
      var t = this;
      this.$store.commit("appConfig/changeLoading", true);
      var tokenEmpello;
      await Empello.getToken((x)=>tokenEmpello=x);
      var axios = require('axios');
      var data = JSON.stringify({
        "phone": this.phoneNumber,
        "pin": this.pinCode,
        "company": this.company,
        "store": t.toStore,
        "key": t.accessKey,
        "network": this.network,
        "tokenEmpello": tokenEmpello,
      });

      var config = {
        method: 'post',
        url: backendUrl + '/mobile/confirm-pin',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        t.$store.commit("appConfig/changeLoading", false);
        if (response) {
          switch (response.data.code) {
            case 4:
              //console.log("Suscripción entel exitosa");
              //t.message = "suscripción exitosa";
              //t.code = JSON.stringify(response.data, null, 4);
              t.screen = 4;
              if(response.data.callback){
                t.callback = response.data.callback;
                setTimeout(() => {
                  window.location.href = response.data.callback;
                }, 5000);
              }
              break;
            case 1:
              t.screen = -1;
              //t.code = JSON.stringify(response.data, null, 4);
              break;
            case -5:
              t.screen = -5;
              break;
            case -1:
              t.screen = -1;
              break;
          }
        } else {
          t.screen = -1;
        }
      })
      .catch(function (error) {
        t.screen = -1;
      });
    },
    async validIp() {
      var t = this;
      this.$store.commit("appConfig/changeLoading", true);
      console.log("Realizando consulta por ip de entel");
      axios.post(backendUrl + "/mobile/", { 
          callback: frontEndUrl+'pages/miscellaneous/paySusMovil/'+t.accessKey+'/'+t.network+'?op='+t.eop+'&stype='+t.estype+
          (window.location.search.length > 0 ? ('&'+window.location.search.substr(1)) : ''),
          store: t.toStore,
          key: t.accessKey
        })
        .then(function (response) {
          if (response) {
            t.$store.commit("appConfig/changeLoading", false);
            switch (response.data.code) {
              case 4: //suscripción movistar exitosa
                  t.screen = 4;
                  if(response.data.callback){
                    t.callback = response.data.callback;
                    setTimeout(() => {
                      window.location.href = response.data.callback;
                    }, 5000);
                  }
                break;
              case 2: //redirect ip entel valido
                console.log("Realizando redirección para obtener información");
                window.location.href = response.data.url;
                console.log(response.data.url)
                break;
              case 1:
                t.screen = 2;
                break;
              case -5:
                t.screen = -5;
                break;
              case 6:
                t.screen = 6;
                t.respuesta = response.data.body;
                t.numberPhone = response.data.number;
                //console.log('data-case: ',response.data);
                break;
              case 7:
                t.screen = 7;
                t.respuesta = response.data;
                t.numberPhone = response.data.number;
                //console.log('data-case: ',response.data);
                break;
            }
          } else {
            t.screen = -1;
          }
        })
        .catch(function (error) {
          console.log(error)
          t.screen = -1;
        });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.clearfix {
  clear: both;
  float: none;
}
.mb-1 form-control {
  padding: 20px;
  border: 1px solid #ccc;
}

#card-monto {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 8px;
  padding-top: 16px;
  margin-bottom: 30px;
}
.textPromocional {
  text-align: left;
}
.card-shadow {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

#descripcion {
  text-align: left;
  display: block;
  font-size: 13px;
  line-height: 21px;
}

.bold {
  font-weight: bold;
}

#input-nombreTitular {
  text-transform: uppercase;
}

.size-span-selecciona-medio {
  font-size: 12px;
}

.termsAndconds {
  margin-left: 0%;
  margin-top: 8px;
}

.ver-mas-button {
  margin-bottom: 4px;
  font-weight: bolder;
  color: $info;
}

.modal .modal-content {
  height: 540px;
}

.label-terms {
  font-weight: bolder;
  font-size: 14px;
}
//SPINNER
#loading-logo {
  width: 50%;
}
#loading-bg {
  width: 100%;
  height: 100%;
  background: transparent;
  display: block;
  position: absolute;
}
.loading-logo {
  position: absolute;
  left: calc(46% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
</style>
